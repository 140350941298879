import { Twitter, Telegram, Discord, Github, Medium } from "../assets/logos";

export const social = [
  {
    title: "Twitter",
    logo: Twitter,
    href: "https://twitter.com/SentinelDVPN",
  },
  {
    title: "Discord",
    logo: Discord,
    href: "https://discord.com/invite/mmAA8qF",
  },
  {
    title: "Github",
    logo: Github,
    href: "https://github.com/sentinel-official",
  },
  {
    title: "Telegram",
    logo: Telegram,
    href: "https://t.me/SentinelDVPN",
  },
  {
    title: "Medium",
    logo: Medium,
    href: "https://medium.com/sentinel",
  },
];

export const groups = [
  {
    title: "Learn",
    links: [
      {
        title: "Sentinel Whitepaper",
        href: "https://docs.sentinel.co/getting-started/whitepaper",
      },
      {
        title: "Cosmos Ecosystem",
        href: "https://cosmos.network/",
      },
      {
        title: "Docs",
        href: "https://docs.sentinel.co/",
      },
    ],
  },
  {
    title: "Explore",
    links: [
      {
        title: "Sentinel Explorer",
        href: "https://www.mintscan.io/sentinel",
      },
      {
        title: "Cosmostation Wallet",
        href: "https://www.cosmostation.io/wallet",
      },
      {
        title: "Keplr Wallet",
        href: "https://www.keplr.app/download",
      },
    ],
  },
  {
    title: "Apps",
    links: [
      {
        title: "Explore DVPN apps",
        href: "https://docs.sentinel.co/getting-started/apps",
      },
    ],
  },
];

export const tncLinks = [
  {
    title: "Terms of Service",
    href: "/",
  },
  {
    title: "Cookies Policy",
    href: "/",
  },
];
